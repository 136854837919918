<template>
    <el-form
        ref="form"
        :model="formData"
        :label-width="labelWidth"
        class="sc-form"
    >
        <el-row :gutter="20">
            <el-col 
                v-for="(item, index) in visibleColumns" 
                :key="index"
                :span="colSpan"
            >
                <el-form-item 
                    :label="item.label"
                    :prop="item.prop"
                >
                    <!-- 输入框 -->
                    <el-input 
                        v-if="!item.type || item.type === 'input'"
                        v-model="formData[item.prop]"
                        readonly
                        class="is-readonly"
                    />
                    <!-- 文本域 -->
                    <el-input
                        v-if="item.type === 'textarea'" 
                        v-model="formData[item.prop]"
                        type="textarea"
                        readonly
                        class="is-readonly"
                    />
                    <!-- 上传组件 -->
                    <div
                        v-if="item.type === 'upload'"
                        class="upload-preview"
                    >
                        <el-image
                            v-if="item.listType === 'picture' && formData[item.prop]"
                            :src="formData[item.prop]"
                            fit="contain"
                            class="preview-image"
                        />
                        <span v-else>{{ formData[item.prop] }}</span>
                    </div>
                    <!-- 数字输入框 -->
                    <el-input 
                        v-if="item.type === 'number'"
                        v-model="formData[item.prop]"
                        readonly
                        class="is-readonly"
                    />
                    
                    <!-- 选择器 -->
                    <el-input
                        v-if="item.type === 'select'"
                        :value="formatSelectValue(item, formData[item.prop])"
                        readonly
                        class="is-readonly"
                    />

                    <!-- 日期选择器 -->
                    <el-input
                        v-if="item.type === 'date'"
                        v-model="formData[item.prop]"
                        readonly
                        class="is-readonly"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item class="form-footer">
            <slot name="footer">
                <div class="footer-buttons">
                    <el-button @click="$emit('cancel')">关闭</el-button>
                </div>
            </slot>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'ScView',
    props: {
        option: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        labelWidth: {
            type: String,
            default: '120px'
        },
        colSpan: {
            type: Number,
            default: 24
        }
    },
    emits: ['cancel'],
    data() {
        return {
            formData: {}
        }
    },
    watch: {
        modelValue: {
            handler(val) {
                this.formData = { ...val }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        visibleColumns() {
            return this.option.column.filter(item => item.viewDisplay !== false)
        }
    },
    methods: {
        // 格式化选择器的值
        formatSelectValue(item, value) {
            if (!item.dicData) return value
            const option = item.dicData.find(opt => opt.value === value)
            return option ? option.label : value
        }
    }
}
</script>

<style scoped>
.sc-form {
    padding: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    position: relative;
}

.form-footer {
    text-align: center;
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 10px 0;
    margin-bottom: 0;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

:deep(.el-form-item) {
    margin-bottom: 18px;
}

:deep(.el-input__wrapper) {
    background-color: #f5f7fa;
}

:deep(.el-select),
:deep(.el-date-picker),
:deep(.el-input) {
    width: 100%;
}

:deep(.is-readonly) {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #909399;
    cursor: not-allowed;
}

:deep(.is-readonly:hover) {
    border-color: #e4e7ed;
}

:deep(.is-readonly .el-input__inner) {
    cursor: not-allowed;
    background-color: #f5f7fa;
    color: #909399;
}

:deep(.el-form-item.form-footer) {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

:deep(.el-form-item.form-footer .el-form-item__content) {
  width: 100%;
  margin-left: 0 !important;  /* 覆盖默认的 margin-left */
}

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.preview-image {
    width: 100px;
    height: 100px;
}
</style> 